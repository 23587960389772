import { Union, Record } from "./fable_modules/fable-library-js.4.17.0/Types.js";
import { union_type, record_type, option_type, string_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { createElement } from "react";
import React from "react";
import { createObj } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ofArray } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { useReact_useReducer_2B9E6EA0 } from "./fable_modules/Feliz.2.7.0/React.fs.js";

class Model extends Record {
    constructor(ErrorMsg) {
        super();
        this.ErrorMsg = ErrorMsg;
    }
}

function Model_$reflection() {
    return record_type("Home.Model", [], Model, () => [["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["DismissError"];
    }
}

function Message_$reflection() {
    return union_type("Home.Message", [], Message, () => [[]]);
}

function init() {
    return new Model(undefined);
}

function update(model, msg) {
    return new Model(undefined);
}

function madeBy(model, dispatch) {
    let elems_3, elems, elems_1, value_27;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        padding: ((90 + "px ") + 30) + "px",
        backgroundColor: "var(--quasar-main)",
        color: "var(--bg-main)",
    }], (elems_3 = [createElement("div", createObj(ofArray([["style", {
        color: "#9a9a9a",
        fontSize: 0.8 + "rem",
    }], (elems = [createElement("span", {
        children: "Made by Quasar AS",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        padding: ((0 + "px ") + 30) + "px",
        marginTop: 8,
    }], (elems_1 = [createElement("span", {
        style: {
            fontSize: 1.5 + "rem",
            color: "#FF8C00",
        },
        children: "aksje.io",
    }), createElement("p", createObj(ofArray([["style", {}], (value_27 = "aksje.io is a norwegian social investment platform which helps investors make better investments through insider trade tracking, short-position updates and letting users share investment ideas with each other. The aksje.io platform focuses on the Oslo Børs stock exchange and provides both web- and smartphone applications.", ["children", value_27])]))), createElement("a", {
        href: "https://www.aksje.io",
        target: "_blank",
        style: {
            fontSize: 0.8 + "rem",
            color: "var(--bg-main)",
        },
        children: "Visit askje.io",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", {
        style: {},
        children: Interop_reactApi.Children.toArray([]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

function head(model, dispatch) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        padding: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: 100,
        textAlign: "center",
    }], (elems = [createElement("span", {
        children: "Software and platforms, on web and mobile, for finance, stocks and other investment.",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function bio(model, dispatch) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 30,
        textAlign: "center",
    }], (elems = [createElement("p", {
        children: "Quasar AS is a norwegian company established 2021.",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function view(model, dispatch) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {}], (elems = [head(model, dispatch), madeBy(model, dispatch), bio(model, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function Home() {
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    return view(patternInput[0], patternInput[1]);
}

