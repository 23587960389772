import { createElement } from "react";
import React from "react";
import { createObj } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { isEmpty, ofArray } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { RouterModule_router, RouterModule_urlSegments } from "./fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { singleton, append, delay, toList } from "./fable_modules/fable-library-js.4.17.0/Seq.js";
import { Home } from "./Home.js";

function navBar() {
    let elems_3, elems_2, elems;
    return createElement("div", createObj(ofArray([["className", "landing-header"], (elems_3 = [createElement("div", createObj(ofArray([["style", {
        display: "flex",
        alignItems: "center",
    }], (elems_2 = [createElement("div", {
        className: "heading-fade",
    }), createElement("div", createObj(ofArray([["style", {}], (elems = [createElement("span", {
        style: {
            fontSize: 1.5 + "rem",
        },
        children: "Quasar AS",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", {
        style: {},
        children: Interop_reactApi.Children.toArray([]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

const notFoundView = createElement("div", createObj(ofArray([["style", {
    display: "flex",
    width: 100 + "vw",
    height: 100 + "vh",
    justifyContent: "center",
    alignItems: "center",
}], (() => {
    const elems = [createElement("h3", {
        className: "title is-3",
        children: "404 - Page not found",
    })];
    return ["children", Interop_reactApi.Children.toArray(Array.from(elems))];
})()])));

function infobar() {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        padding: 10,
        textAlign: "center",
        color: "#9a9a9a",
    }], (elems = [createElement("span", {
        children: "Quasar AS, Org.nr 927446162",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function App() {
    const patternInput = useFeliz_React__React_useState_Static_1505(RouterModule_urlSegments(window.location.hash, 1));
    const children = toList(delay(() => append(singleton(RouterModule_router({
        onUrlChanged: patternInput[1],
    })), delay(() => append(singleton(navBar()), delay(() => append(isEmpty(patternInput[0]) ? singleton(createElement(Home, null)) : singleton(notFoundView), delay(() => singleton(infobar())))))))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

